<template>
  <div class="box-started">
    <div id="page_news" class="container">
      <div class="show-this-page">
        <span>
          <router-link to="/">Home</router-link>
        </span>
        <span class="space">></span>
        <span>
          <CheckPath
            :routePath="$route.path"
            :routeName="$route.params.category"
            :routeSubname="$route.subname"
            :routeId="$route.params.id"
          />
        </span>
      </div>
      <h1 class="page-title" v-if="$route.params.id == undefined">
        <span v-if="$route.params.category == 'Business'"
          >Business Contents</span
        >
        <span v-else-if="$route.params.category == 'Academy'">Academy</span>
        <span v-else-if="$route.params.category == 'Media'">Media</span>
        <span v-else-if="$route.params.category == 'Interesting'"
          >interesting</span
        >
        <span v-else-if="$route.params.category == 'digital'"
          >Digital Platform</span
        >
        <span v-else>{{ $route.params.category }}</span>
      </h1>
      <h1 class="page-title" v-else>
        <span v-if="$route.params.id == 'online'">online learning</span>
        <span v-else-if="$route.params.id == 'hybrid'">hybrid learning</span>
        <span v-else-if="$route.params.id == 'news'">activity news</span>
        <span v-else-if="$route.params.id == 'youtube'">youtube</span>
        <span v-else-if="$route.params.id == 'podcast'">podcast</span>
        <span v-else-if="$route.params.id == 'navigator'"
          >Business Navigator</span
        >
        <span v-else-if="$route.params.id == 'yellow'">Yellow Digital</span>
        <span v-else-if="$route.params.id == 'mana'">MANA</span>
      </h1>
      <!-- <CategorySubPage
        :routeName="$route.params.category"
        @sendCategory="sendCategory"
      /> -->

      <div class="columns mt-5">
        <div class="column is-two-thirds">
          <div class="text-center" v-if="total == 0 && loading == false">
            <h1 style="font-size: 24px">ไม่พบผลลัพธ์ในหมวดหมู่ที่คุณเลือก</h1>
          </div>
          <div style="position: relative">
            <b-loading v-model="loading" :is-full-page="false"> </b-loading>
            <div class="columns set-flex">
              <div
                class="column is-half"
                v-for="(data_news, index) in blogByCategory"
                :key="index"
              >
                <CardNews
                  :news="data_news"
                  :routeName="$route.params.category"
                />
              </div>
              <div class="column is-full" v-if="total > 10">
                <b-pagination
                  :total="total"
                  :current="current"
                  :per-page="perPage"
                  icon-prev="chevron-left"
                  icon-next="chevron-right"
                  aria-next-label="Next page"
                  aria-previous-label="Previous page"
                  aria-page-label="Page"
                  aria-current-label="Current page"
                  :simple="true"
                  @change="changePage"
                >
                </b-pagination>
              </div>
            </div>
          </div>
        </div>
        <div class="column pt-0" style="padding-bottom: 70px">
          <div class="column is-full">
            <div
              class="box-social"
              v-for="(data_social, index) in socialList"
              :key="index"
            >
              <Social :social="data_social" />
            </div>
          </div>
          <div class="column is-full">
            <div style="position: relative">
              <b-loading v-model="loading" :is-full-page="false"> </b-loading>
              <HomePopular :blogPopular="blogPopular" v-if="loading == false" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scope>
.pagination.is-simple {
  .info {
    display: none;
  }
}
</style>
<script>
import CardNews from "@/components/Card";
import Social from "@/components/Social";
import CheckPath from "@/components/CheckPath";
import CardAcademy from "@/components/CardAcademy";
import HomePopular from "@/components/HomePopular";
// import CategorySubPage from "@/components/CategorySubPage";
import { BLOG } from "@/service/axios.js";
export default {
  data() {
    return {
      total: 0,
      current: 1,
      perPage: 10,
      blogPopular: [],
      blogByCategory: [],
      commonOut: "",
      loading: true,
    };
  },
  components: {
    CardNews,
    Social,
    HomePopular,
    CheckPath,
    CardAcademy,
    // CategorySubPage,
  },
  computed: {
    nameCategory() {
      return this.$route.params.category;
    },
    nameParamsId() {
      return this.$route.params.id;
    },
    socialList() {
      return this.$store.state.socialList;
    },
  },
  mounted() {
    this.getBlogPopular();
    this.getBlog();
  },
  watch: {
    nameCategory() {
      this.getBlog();
    },
    nameParamsId() {
      if (this.$route.params.id != undefined) {
        this.getBlog();
      }
    },
  },
  methods: {
    sendCategory(cat) {
      this.commonOut = cat;
      console.log(this.commonOut);
      (this.loading = true), this.getBlog();
    },
    changePage(page) {
      this.current = page;
      this.getBlog();
      this.current = 1;
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    },
    getBlog() {
      var nameCategory = this.$route.params.category;
      var params = this.$route.params.id;
      console.log("commonOut", this.commonOut);
      if (nameCategory != "Business") {
        if (this.commonOut == "") {
          console.log("params", params);
          if (params != undefined) {
            params = nameCategory + "_" + params;
            nameCategory = params;
          } else {
            nameCategory = this.$route.params.category;
          }
        } else if (params != undefined) {
          params = nameCategory + "_" + params;
          nameCategory = params;
        } else {
          nameCategory = this.commonOut;
        }
      }
      console.log(this.nameCategory);
      BLOG.get(
        `posts?key=db9af1ab9fa83c98a75d2f98bb&page=${this.current}&limit=${this.perPage}b&filter=tag:${nameCategory}&include=tags`
      )
        .then(async (res) => {
          if (res.status == 200) {
            this.blogByCategory = res.data.posts;
            this.total = res.data.meta.pagination.total;
            this.loading = false;
            console.log("blogByCategory", this.blogByCategory);
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("get blog error");
        });
    },
    getBlogPopular() {
      BLOG.get(
        `posts?key=db9af1ab9fa83c98a75d2f98bb&page=1&limit=5b&filter=featured:true&include=tags`
      )
        .then(async (res) => {
          if (res.status == 200) {
            this.blogPopular = res.data.posts;
            console.log("blogPopular", this.blogPopular);
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("get blog error");
        });
    },
  },
};
</script>