<template>
  <span>
    <!-- <p>{{ routeId }}</p>   -->
   <a :href="'/category/'+routeName.toLowerCase()">{{ routeName }}</a>
   <!-- <a href=""></a> -->
    <span v-if="routeId != undefined">
      <span class="space">></span>
      <span>{{ routeId }}</span>
    </span>
  </span>
</template>
<script>
export default {
  props: ["routePath", "routeName", "routeSubname", "routeId"],
};
</script>